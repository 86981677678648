// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as React from "react";

((require('./Modal.css')));

function Modal(Props) {
  var visible = Props.visible;
  var onToggle = Props.onToggle;
  if (visible) {
    return React.createElement(React.Fragment, undefined, React.createElement("div", {
                    className: "Modal-overlay",
                    onClick: onToggle
                  }), React.createElement("div", {
                    className: "Modal-container"
                  }, React.createElement("button", {
                        className: "Modal-close",
                        onClick: onToggle
                      }, React.createElement("img", {
                            className: "Modal-close--icon",
                            height: "18",
                            src: "/images/close.svg",
                            width: "18"
                          })), React.createElement("h3", {
                        className: "Modal-title"
                      }, "Enjoy your avatar!"), React.createElement("span", {
                        className: "Modal-subtitle"
                      }, "Share it with your friends and see what ridiculousness they come up with!"), React.createElement("div", {
                        className: "Modal-buttons"
                      }, React.createElement("a", {
                            className: "Modal-button Modal-button--twitter",
                            href: "https://twitter.com/intent/tweet?text=Generate+a+modern+avatar+with+Avatar+by+@MediaZ&url=https%3A%2F%2FAvatar.MediaZ.club&related=MediaZ,bluerssen,peterpme,daltondubya,donaldhruska",
                            rel: "no-follow",
                            target: "_blank"
                          }, React.createElement("img", {
                                className: "Modal-shareIcon",
                                height: "16",
                                src: "/images/twitter.svg",
                                width: "16"
                              }), React.createElement("span", undefined, "Twitter")), React.createElement("a", {
                            className: "Modal-button Modal-button--ph",
                            href: "https://www.producthunt.com/posts/Avatar-by-MediaZ",
                            rel: "no-follow",
                            target: "_blank"
                          }, React.createElement("img", {
                                className: "Modal-shareIcon",
                                height: "16",
                                src: "/images/producthunt.svg",
                                width: "16"
                              }), React.createElement("span", undefined, "Product Hunt")))));
  } else {
    return null;
  }
}

var make = Modal;

export {
  make ,
  
}
/*  Not a pure module */
