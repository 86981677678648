// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as React from "react";

function Wordmark(Props) {
  return React.createElement("a", {
              title: "MediaZ Solutions",
              href: "https://mediaz.vn"
            }, React.createElement("img", {
                  src: "https://mediaz.vn/wp-content/uploads/2020/04/logo-mediaz.png"
                }));
}

var make = Wordmark;

export {
  make ,
  
}
/* react Not a pure module */
