// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Gatsby from "gatsby";
import * as Js_math from "bs-platform/lib/es6/js_math.js";
import * as Caml_array from "bs-platform/lib/es6/caml_array.js";
import * as ExportImageJs from "../externals/exportImage.js";
import * as App$MediaZPersonaAvatarGenerator from "../re/App.bs.js";
import * as Layout$MediaZPersonaAvatarGenerator from "../components/Layout.bs.js";

import  {graphql}  from "gatsby"
;

function exportImageAsync(prim) {
  ExportImageJs.default();
  
}

var defaultStyles = {
  skin: "Skin",
  skinColor: "B16A5B",
  hair: "Balding",
  hairColor: "E16381",
  facialHair: "Mustache",
  facialHairColor: "6C4545",
  body: "Square",
  bodyColor: "5A45FF",
  eyes: "Glasses",
  mouth: "Pacifier",
  nose: "Smallround",
  bgColor: "FFCC65",
  head: "Head"
};

function randomizeStyles(config) {
  var getRandom = function (_list) {
    var len = _list.length;
    return Caml_array.caml_array_get(_list, Js_math.floor(Math.random() * len));
  };
  return {
          skin: "Skin",
          skinColor: getRandom(config.skinColors),
          hair: getRandom(config.hairStyles),
          hairColor: getRandom(config.hairColors),
          facialHair: getRandom(config.facialHairStyles),
          facialHairColor: getRandom(config.facialHairColors),
          body: getRandom(config.bodyStyles),
          bodyColor: getRandom(config.bodyColors),
          eyes: getRandom(config.eyeStyles),
          mouth: getRandom(config.mouthStyles),
          nose: getRandom(config.noseStyles),
          bgColor: getRandom(config.bgColors),
          head: "Head"
        };
}

function Index(Props) {
  var data = Gatsby.useStaticQuery((graphql`
  query ConfigQuery {
    allDataJson {
      edges {
        node {
          skinStyles
          skinColors
          hairStyles
          hairColors
          facialHairStyles
          facialHairColors
          bodyStyles
          bodyColors
          eyeStyles
          mouthStyles
          noseStyles
          bgStyles
          bgColors
          disabledColors
        }
      }
    }
  }
   `));
  var match = React.useState((function () {
          return defaultStyles;
        }));
  var setStyles = match[1];
  var match$1 = React.useState((function () {
          return false;
        }));
  var setShowModal = match$1[1];
  var onChange = function (key, value) {
    return Curry._1(setStyles, (function (styles) {
                  if (key >= 9010881) {
                    if (key >= 741833537) {
                      if (key >= 971528446) {
                        if (key >= 977973903) {
                          return {
                                  skin: styles.skin,
                                  skinColor: styles.skinColor,
                                  hair: value,
                                  hairColor: styles.hairColor,
                                  facialHair: styles.facialHair,
                                  facialHairColor: styles.facialHairColor,
                                  body: styles.body,
                                  bodyColor: styles.bodyColor,
                                  eyes: styles.eyes,
                                  mouth: styles.mouth,
                                  nose: styles.nose,
                                  bgColor: styles.bgColor,
                                  head: styles.head
                                };
                        } else {
                          return {
                                  skin: styles.skin,
                                  skinColor: styles.skinColor,
                                  hair: styles.hair,
                                  hairColor: styles.hairColor,
                                  facialHair: styles.facialHair,
                                  facialHairColor: styles.facialHairColor,
                                  body: styles.body,
                                  bodyColor: styles.bodyColor,
                                  eyes: styles.eyes,
                                  mouth: styles.mouth,
                                  nose: value,
                                  bgColor: styles.bgColor,
                                  head: styles.head
                                };
                        }
                      } else if (key >= 863241606) {
                        return {
                                skin: styles.skin,
                                skinColor: value,
                                hair: styles.hair,
                                hairColor: styles.hairColor,
                                facialHair: styles.facialHair,
                                facialHairColor: styles.facialHairColor,
                                body: styles.body,
                                bodyColor: styles.bodyColor,
                                eyes: styles.eyes,
                                mouth: styles.mouth,
                                nose: styles.nose,
                                bgColor: styles.bgColor,
                                head: styles.head
                              };
                      } else {
                        return {
                                skin: styles.skin,
                                skinColor: styles.skinColor,
                                hair: styles.hair,
                                hairColor: styles.hairColor,
                                facialHair: styles.facialHair,
                                facialHairColor: styles.facialHairColor,
                                body: styles.body,
                                bodyColor: value,
                                eyes: styles.eyes,
                                mouth: styles.mouth,
                                nose: styles.nose,
                                bgColor: styles.bgColor,
                                head: styles.head
                              };
                      }
                    } else if (key >= 527084835) {
                      return {
                              skin: styles.skin,
                              skinColor: styles.skinColor,
                              hair: styles.hair,
                              hairColor: styles.hairColor,
                              facialHair: value,
                              facialHairColor: styles.facialHairColor,
                              body: styles.body,
                              bodyColor: styles.bodyColor,
                              eyes: styles.eyes,
                              mouth: styles.mouth,
                              nose: styles.nose,
                              bgColor: styles.bgColor,
                              head: styles.head
                            };
                    } else {
                      return {
                              skin: styles.skin,
                              skinColor: styles.skinColor,
                              hair: styles.hair,
                              hairColor: value,
                              facialHair: styles.facialHair,
                              facialHairColor: styles.facialHairColor,
                              body: styles.body,
                              bodyColor: styles.bodyColor,
                              eyes: styles.eyes,
                              mouth: styles.mouth,
                              nose: styles.nose,
                              bgColor: styles.bgColor,
                              head: styles.head
                            };
                    }
                  } else if (key >= -436687089) {
                    if (key >= -304916875) {
                      if (key >= -180148022) {
                        return {
                                skin: styles.skin,
                                skinColor: styles.skinColor,
                                hair: styles.hair,
                                hairColor: styles.hairColor,
                                facialHair: styles.facialHair,
                                facialHairColor: styles.facialHairColor,
                                body: styles.body,
                                bodyColor: styles.bodyColor,
                                eyes: styles.eyes,
                                mouth: value,
                                nose: styles.nose,
                                bgColor: styles.bgColor,
                                head: styles.head
                              };
                      } else {
                        return {
                                skin: styles.skin,
                                skinColor: styles.skinColor,
                                hair: styles.hair,
                                hairColor: styles.hairColor,
                                facialHair: styles.facialHair,
                                facialHairColor: styles.facialHairColor,
                                body: styles.body,
                                bodyColor: styles.bodyColor,
                                eyes: styles.eyes,
                                mouth: styles.mouth,
                                nose: styles.nose,
                                bgColor: value,
                                head: styles.head
                              };
                      }
                    } else if (key >= -315279020) {
                      return {
                              skin: value,
                              skinColor: styles.skinColor,
                              hair: styles.hair,
                              hairColor: styles.hairColor,
                              facialHair: styles.facialHair,
                              facialHairColor: styles.facialHairColor,
                              body: styles.body,
                              bodyColor: styles.bodyColor,
                              eyes: styles.eyes,
                              mouth: styles.mouth,
                              nose: styles.nose,
                              bgColor: styles.bgColor,
                              head: styles.head
                            };
                    } else {
                      return {
                              skin: styles.skin,
                              skinColor: styles.skinColor,
                              hair: styles.hair,
                              hairColor: styles.hairColor,
                              facialHair: styles.facialHair,
                              facialHairColor: styles.facialHairColor,
                              body: value,
                              bodyColor: styles.bodyColor,
                              eyes: styles.eyes,
                              mouth: styles.mouth,
                              nose: styles.nose,
                              bgColor: styles.bgColor,
                              head: styles.head
                            };
                    }
                  } else if (key >= -441878187) {
                    return {
                            skin: styles.skin,
                            skinColor: styles.skinColor,
                            hair: styles.hair,
                            hairColor: styles.hairColor,
                            facialHair: styles.facialHair,
                            facialHairColor: value,
                            body: styles.body,
                            bodyColor: styles.bodyColor,
                            eyes: styles.eyes,
                            mouth: styles.mouth,
                            nose: styles.nose,
                            bgColor: styles.bgColor,
                            head: styles.head
                          };
                  } else {
                    return {
                            skin: styles.skin,
                            skinColor: styles.skinColor,
                            hair: styles.hair,
                            hairColor: styles.hairColor,
                            facialHair: styles.facialHair,
                            facialHairColor: styles.facialHairColor,
                            body: styles.body,
                            bodyColor: styles.bodyColor,
                            eyes: value,
                            mouth: styles.mouth,
                            nose: styles.nose,
                            bgColor: styles.bgColor,
                            head: styles.head
                          };
                  }
                }));
  };
  var randomize = function (param) {
    var config = Caml_array.caml_array_get(data.allDataJson.edges, 0).node;
    return Curry._1(setStyles, (function (param) {
                  return randomizeStyles(config);
                }));
  };
  var config = Caml_array.caml_array_get(data.allDataJson.edges, 0).node;
  return React.createElement(Layout$MediaZPersonaAvatarGenerator.make, {
              children: React.createElement(App$MediaZPersonaAvatarGenerator.make, {
                    showModal: match$1[0],
                    onToggleModal: (function (param) {
                        return Curry._1(setShowModal, (function (param) {
                                      return false;
                                    }));
                      }),
                    onExport: (function (param) {
                        Curry._1(setShowModal, (function (param) {
                                return true;
                              }));
                        ExportImageJs.default();
                        
                      }),
                    onChange: onChange,
                    randomize: randomize,
                    styles: match[0],
                    config: config
                  })
            });
}

var make = Index;

var $$default = Index;

export {
  exportImageAsync ,
  defaultStyles ,
  randomizeStyles ,
  make ,
  $$default ,
  $$default as default,
  
}
/*  Not a pure module */
