// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Js_exn from "bs-platform/lib/es6/js_exn.js";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as Caml_int32 from "bs-platform/lib/es6/caml_int32.js";
import * as Icon$MediaZPersonaAvatarGenerator from "./Icon.bs.js";
import * as Types$MediaZPersonaAvatarGenerator from "./Types.bs.js";
import * as Styler$MediaZPersonaAvatarGenerator from "./Styler.bs.js";
import * as IconLink$MediaZPersonaAvatarGenerator from "./IconLink.bs.js";
import * as SvgLoader$MediaZPersonaAvatarGenerator from "./SvgLoader.bs.js";

((require('./AvatarGenerator.css')));

function getZIndex(id) {
  if (id >= 771219970) {
    if (id !== 803296066) {
      if (id >= 870531891) {
        if (id >= 925778589) {
          return "30";
        } else {
          return "100";
        }
      } else if (id >= 803493184) {
        return "40";
      } else {
        return "110";
      }
    } else {
      return "70";
    }
  } else if (id >= 529696487) {
    if (id >= 737453762) {
      return "75";
    } else {
      return "80";
    }
  } else if (id >= 483301390) {
    return "20";
  } else {
    return "90";
  }
}

function AvatarGenerator(Props) {
  var randomize = Props.randomize;
  var settings = Props.settings;
  var onChange = Props.onChange;
  var onExport = Props.onExport;
  var match = React.useReducer((function (state, action) {
          Curry._1(randomize, undefined);
          return {
                  rotation: state.rotation + 1 | 0
                };
        }), {
        rotation: 0
      });
  var dispatch = match[1];
  var rotation = "rotate(" + (String(Caml_int32.imul(match[0].rotation, 50)) + "deg)");
  var pngImage = Belt_Array.map(settings, (function (o) {
          return React.createElement(SvgLoader$MediaZPersonaAvatarGenerator.make, {
                      style: {
                        zIndex: getZIndex(o.id)
                      },
                      className: "AvatarGenerator-png",
                      name: o.selectedStyle,
                      fill: "#" + o.selectedColor,
                      size: "512",
                      key: o.label
                    });
        }));
  var faceFeatures = Belt_Array.map(settings, (function (o) {
          return React.createElement(SvgLoader$MediaZPersonaAvatarGenerator.make, {
                      style: {
                        zIndex: getZIndex(o.id)
                      },
                      className: "AvatarGenerator-faceFeature",
                      name: o.selectedStyle,
                      fill: "#" + o.selectedColor,
                      size: "150",
                      key: o.label
                    });
        }));
  return React.createElement("div", {
              className: "AvatarGenerator-container"
            }, React.createElement("div", {
                  className: "AvatarGenerator-pngContainer"
                }, pngImage), React.createElement("div", {
                  className: "AvatarGenerator-avatar"
                }, faceFeatures), React.createElement("button", {
                  className: "Text-link",
                  onClick: (function (param) {
                      return Curry._1(dispatch, /* Randomize */0);
                    })
                }, "Randomize", React.createElement(Icon$MediaZPersonaAvatarGenerator.make, {
                      style: {
                        transform: rotation
                      },
                      name: "randomize"
                    })), React.createElement("div", {
                  className: "AvatarGenerator-row"
                }, Belt_Array.map(settings, (function (o) {
                        return React.createElement(Styler$MediaZPersonaAvatarGenerator.make, {
                                    id: o.id,
                                    label: o.label,
                                    colors: o.colors,
                                    styles: o.styles,
                                    selectedColor: o.selectedColor,
                                    selectedStyle: o.selectedStyle,
                                    onSelectColor: (function (color) {
                                        var match = o.id;
                                        var key = match >= 737453762 ? (
                                            match !== 803296066 ? (
                                                match !== 925778589 ? (
                                                    match >= 737453763 ? Js_exn.raiseError("ColorNotFound: " + Types$MediaZPersonaAvatarGenerator.idToJs(o.id)) : /* BodyColor */741833537
                                                  ) : /* SkinColor */863241606
                                              ) : /* HairColor */9010881
                                          ) : (
                                            match !== -697605842 ? (
                                                match !== 483301390 ? Js_exn.raiseError("ColorNotFound: " + Types$MediaZPersonaAvatarGenerator.idToJs(o.id)) : /* BackgroundColor */-304916875
                                              ) : /* FacialHairColor */-441878187
                                          );
                                        return Curry._2(onChange, key, color);
                                      }),
                                    onSelectStyle: (function (style) {
                                        var match = o.id;
                                        var key;
                                        var exit = 0;
                                        if (match >= 771219970) {
                                          if (match >= 870531891) {
                                            if (match !== 925778589) {
                                              if (match >= 870531892) {
                                                exit = 1;
                                              } else {
                                                key = /* NoseStyle */971528446;
                                              }
                                            } else {
                                              key = /* SkinStyle */-315279020;
                                            }
                                          } else if (match !== 803296066) {
                                            if (match >= 771219971) {
                                              exit = 1;
                                            } else {
                                              key = /* EyesStyle */-566842417;
                                            }
                                          } else {
                                            key = /* HairStyle */977973903;
                                          }
                                        } else if (match !== -697605842) {
                                          if (match !== 529696487) {
                                            if (match !== 737453762) {
                                              exit = 1;
                                            } else {
                                              key = /* BodyStyle */-436687089;
                                            }
                                          } else {
                                            key = /* MouthStyle */-180148022;
                                          }
                                        } else {
                                          key = /* FacialHairStyle */527084835;
                                        }
                                        if (exit === 1) {
                                          key = Js_exn.raiseError("StyleNotFound: " + Types$MediaZPersonaAvatarGenerator.idToJs(o.id));
                                        }
                                        return Curry._2(onChange, key, style);
                                      }),
                                    key: o.label
                                  });
                      }))), React.createElement("button", {
                  className: "Button-primary Text-button",
                  onClick: onExport
                }, "Download Avatar"), React.createElement("div", {
                  className: "AvatarGenerator-footer"
                }, React.createElement("img", {
                      height: "32",
                      src: "/images/sketch.svg",
                      width: "32"
                    }), React.createElement("div", {
                      className: "AvatarGenerator-footerTop"
                    }, React.createElement("span", {
                          className: "Text-cta-title mb-1"
                        }, "Want to create a CV?"), React.createElement("br", undefined), React.createElement("span", {
                          className: "Text-cta-subtitle"
                        }, "Resumes and more.")), React.createElement(IconLink$MediaZPersonaAvatarGenerator.make, {
                      label: "Let's Go",
                      href: "https://cv.mediaz.vn",
                      icon: "arrow"
                    })));
}

var make = AvatarGenerator;

export {
  getZIndex ,
  make ,
  
}
/*  Not a pure module */
